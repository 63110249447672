<template>
  <v-content style="width: 375px; background-color: #F9F9F9;" class="mx-auto my-0 pa-0 pb-12">
    <div class="mx-5">
      <div v-if="ticket_list != null">
        <p class="text-center mt-8 mb-0" style="font: bold 18px/27px;">
          取得済みチケット
        </p>
        <v-card width="335px" class="mt-5" v-for="(ticket, index) in ticket_list" :key="index">
          <v-overlay opacity="0.7" absolute v-if="ticket.is_use != 0">
            <v-row align="center" justify="center" no-gutters>
              <div class="text-center" style="width: 335px; height: 173px;">
                <div v-if="ticket.is_use == 1">
                  <img class="img" src="@/assets/check.svg" width="30">
                  <p class="mt-7 mb-0 subtitle">
                    チケット使用済み
                  </p>
                </div>
                <div v-if="ticket.is_use == 2">
                  <img class="img" src="@/assets/sandtimer.svg" width="30">
                  <p class="mt-7 mb-0" style="font: bold 16px/24px;">
                    チケット有効期限切れ
                  </p>
                </div>
                <v-btn class="mt-6 graybtn w70" rounded @click.stop="onOpenDialog(ticket.id, index)">
                  削除する
                </v-btn>
              </div>
            </v-row>
          </v-overlay>
          <v-img :src="ticket.image_path" v-if="ticket.image_path" style="border-radius: 16px 16px 0 0; height: 163px;"></v-img>
          <p class="mt-4 mb-0 text-center maincolor" style="font: bold 12px/20px;">{{ticket.title}}</p>
          <p class="mt-2 mb-0 text-center maincolor" style="font: bold 16px/20px;">
            {{ticket.complete_title}}
          </p>
          <p class="mt-4 mb-0 text-center maincolor" style="font: bold 11px/16px;">
            チケット有効期限：{{ticket.expiration_date}}
          </p>
          <p class="mt-4 mb-0 mx-4 text-center" style="font: normal 13px/20px; color: #333333;" v-html="ticket.privilege_detail">
          </p>
          <hr class="mt-4 mx-3" size="1px" color="#DEDEDF">
          <v-row align="start" justify="center" no-gutters class="mt-4 mx-3">
            <v-col cols="3">
              <p class="mb-0" style="font: bold 11px/18px; color: #9D9D9D;">
                注意事項
              </p>
            </v-col>
            <v-col cols="9">
              <p class="mb-0" style="font: normal 11px/16px 'YuGothic'; color: #333333;" v-html="ticket.privilege_text">
              </p>
            </v-col>
          </v-row>
          <hr class="mt-4 mx-3" size="1px" color="#DEDEDF">
          <div class="mt-6 text-center">
            <v-btn class="mb-6 maincolor-bg" width="200px" height="32px" dark rounded @click.stop="openUseDialog(ticket, index)">
                取得チケットを使う
            </v-btn>
          </div>
        </v-card>
      </div>

      <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" spinner="spiral">
        <p slot="spinner" class="pt-2">ロード中...</p>
        <div slot="no-more"></div>
        <p slot="no-results" class="pt-2">コンプリートチケットはありません</p>
      </infinite-loading>

      <TicketDialog ref="qrDialog" :init="init" @use="use" @pollingStop="pollingStop" />
      <!-- チケット使用ダイアログ -->
      <ticketConfirmDialog ref="ticketConfirmDialog" @use="use"></ticketConfirmDialog>
      <v-dialog v-model="dialog" persistent>
        <v-card class="ticket_dialogue text-center pa-5">
          <p class="body-2 mb-10">チケットを削除しますか？</p>
          <div class="text-center">
            <v-btn rounded class="maincolor-bg default_button" @click.stop="onDeleteTicket(ticket_id, ticket_index)">削除する</v-btn>
            <br><br>
            <v-btn rounded class="caption font-weight-black mb-10" style="background-color:#EFEFEF;" @click="dialog = false">キャンセルする</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <div class="mt-12 text-center">
      <v-btn rounded class="mb-10 maincolor-bg default_button" @click="routerBindTo({ name: 'wpoint' })">スタンプに戻る</v-btn>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import TicketDialog from '../../components/ticket_dialogue.vue'
import ticketConfirmDialog from '../../components/ticket_confirm_dialog.vue'
export default {
  components: {
    TicketDialog,
    ticketConfirmDialog,
    InfiniteLoading
  },
  data: () => ({
    init: [],
    ticket_list: null,
    stamp_id: null,
    page: 1,
    dialog: false,
    operationTicket: null,
    target_id: null,
    ticket_index: null,
    ticket_id: null,
    comp_ticket_id: null,
    loading: false,
    intervalId: undefined,
  }),
  created () {
    this.init = this.storageGet('*')
  },
  methods: {
    async use(code) {
      try {
        //loadingを表示
        this.loading = true
        //QR認証を設定している場合はポーリングされているので、ポーリングを停止
        this.pollingStop()
        //念の為もう一度ローカルから取得（後ほど処理変える）
        this.init = this.storageGet('*')

        var params = {
          'sid': this.init.sid,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'id': this.target_id,
          'comp_ticket_id': this.comp_ticket_id,
          'code': code,
          'type': "2",
          'shop_id': String(this.init.sid),
        }

        var res = await this.apiCall('/user/ticket/use', params, 'post', 0);
        if (!res) {
          this.loading = false;
          return false
        }
        this.loading = false
        this.ticket_list[this.ticket_index]['is_use'] = 1;

      } catch(e) {
        this.loading = false
        this.callDialog('エラー', 'エラーが発生いたしました。', 3);
      }
    },
    async openUseDialog(item, index) {
        // 対象のIDを保存しておく
        this.target_id = item.id
        this.comp_ticket_id = item.complete_ticket_id
        this.ticket_index = index

        if (item.auth_flg == 1) {
            var qr_url = "useTicket://?id=" + item.id + "&device_uid=" + this.init.device_uid + "&type=2" + "&os=" + this.init.os + "&device_id=" + this.init.device_id + "&sid=" + this.init.sid

            // ダイアログ表示
            this.$refs.qrDialog.open(item.id, item.auth_flg, qr_url, item);

            var count = 0
            var baseData = this.storageGet('*')
            var apiCall  = this.apiCall
            var router = this.$router
            var callDialog = this.callDialog
            var comp_id = this.comp_ticket_id
            var stamp_id = this.init.stamp_id
            var thisone = this

            var useCheck = async function() {
              console.log("checking...")
              count++

              var params = { params:{
                'sid': baseData.sid,
                'service_cd': baseData.service_cd,
                'device_uid': baseData.device_uid,
                'device_id': baseData.device_id,
                'os': baseData.os,
                'key': baseData.key,
                'id': item.id,
                'stamp_id': stamp_id,
                'comp_ticket_id': comp_id,
                'page': 1,
              }}

              try{
                var res = await apiCall('/stamp/ticket', params, 'get');
                if (res.data[0].is_use == 1) {
                    thisone.pollingStop()
                    router.go({path: router.currentRoute.path, force: true});
                }
                if (!res) return false;
              } catch(e) {
                  callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
              }
            }

            thisone.intervalId = setInterval(function(){ //3秒ごとにapiを叩く
                console.log('polling START');
                useCheck();
                if (count >= 20){ //1分後にポーリング終了
                    thisone.pollingStop()
                }
            }, 3000);

        } else {
            this.$refs.ticketConfirmDialog.open()
        }

    },
    onClickBtn(id, flg) {
      this.$refs.compdialog.open(id, flg);
    },
    onUseTicket(id) {
      this.$refs.confirmdialog.open(id);
    },
    onOpenDialog(id, index) {
      this.ticket_id = id
      this.ticket_index = index
      this.dialog = true
    },
    pollingStop() {
      console.log('polling END');
      clearInterval(this.intervalId);
    },
    async onDeleteTicket(id, index) {
      var res = await this.apiCall('/stamp/ticket-delete', {'id': id}, 'post', 0)
      if (!res) return false;

      this.$delete(this.ticket_list, index)
      this.dialog = false
    },
    async infiniteHandler() {
      this.getUrlParam();
      this.init = this.storageGet('*');
      var res = await this.apiCall('/stamp/ticket', {params: {stamp_id: this.init.stamp_id, page: this.page}});

      if (res.data && res.data.length > 0) {
          if (this.page == 1) {
              this.ticket_list = res.data;
          } else {
              this.ticket_list = this.ticket_list.concat(res.data);
          }
          this.$refs.infiniteLoading.stateChanger.loaded()
      } else {
          this.$refs.infiniteLoading.stateChanger.complete()
      }
      this.page = this.page + 1

      if (!this.ticket_list && !this.ticket_list.length) {
        this.scrollMoveTop();
      }
    },
  }
}
</script>

<style>
 .v-application .text-center > p > img {
  width: 100% !important;
 }
</style>
